<template>
    
    <tr>
        <td>{{index+1 }}</td>
        <td>{{item.name}}</td>
        <td>{{getMasterLabelByType(item.recurringtype,MASTER_RECURRING_TYPES)}}</td>
        <td>{{item.numberofemi}}</td>
        <td>{{item.emi}}</td>
        <td>{{item.totalamount}}</td>
        <td>{{item.description}}</td>
        <td @dblclick="showincentive(item)" @keyup.enter="showincentive(item)" style="color:green">
            <input v-if="incentivefield" type="text" :placeholder="item.commtotalproduct" v-model="item.commtotalproduct">
            <span>{{ item.commtotalproduct }}</span>
        </td>
        <td @dblclick="showcommission(item)" @keyup.enter="showcommission(item)" style="color:green">
            <input v-if="commissionfield" type="text" :placeholder="item.commission" v-model="item.commission">
            <span>{{ item.commission }} %</span>
        </td>
        <td v-if="loggedinuser!=null && loggedinuser.accessids.includes('23')">
            <button type="button" class="btn btn-success btn-xs" style="width:70px;padding:2px" @click="edit(item)">
                <i class="fa fa-edit"></i> Edit
            </button>
        </td>
    </tr>
  
</template>
<script>
// import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
export default {
    mixins:[Constants],
    props:['item','index'],
    data() {
        return {
            commissionfield:false,
            incentivefield:false,
        }
    },
    methods: {
        showcommission(){
            this.commissionfield=!this.commissionfield
            let param={id:this.item.id,column:"commission",value:this.item.commission}
            this.$http.post('api/scheme/updatefield',param)
            .then((response) => this.processUpdateResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },    
        showincentive(){
            this.incentivefield=!this.incentivefield
            let param={id:this.item.id,column:"commtotalproduct",value:this.item.commtotalproduct}
            this.$http.post('api/scheme/updatefield',param)
            .then((response) => this.processUpdateResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },    
    },
    // computed:{
	// 	...mapGetters([
	// 	    'schemes'
	// 	]),
	// },
}
</script>