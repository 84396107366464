<template>
<div>
<div class="col-md-12">
    <div class="row" style="margin-top:20px">
        <div class="col-md-6">
            <h3 class="page-header">
            <i class="fa fa fa-user animated bounceInDown show-info"></i>
            Schemes
            </h3>
        </div>
        <div class="col-md-6 ">
            <div class="row">
                <div class="col-md-10" >
                    <div class="row" style="padding-top:18px;justify-content:flex-end;">
                       <div  class="col-md-2" style="margin-right:25px;">             
                            <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </div>
</div>
    
    <template v-if="viewno==1">
    <div class="col-md-12">
      
    </div>
    
   
   
    <div class="col-md-12">
        <div class="panel">
            <div class="panel-body">
                <table class="table users-table table-condensed table-hover" id="tblmanual">
                    <thead class="table-font">
                        <tr>
                            <th class="visible-lg">#</th>
                            <th class="visible-lg">Name</th>
                            <th>Recurring Type</th>
                            <th>No of Emi</th>
                            <th>Emi Amount</th>
                            <th class="visible-lg">Total Amount</th>
                            <th class="visible-lg">Description</th>
                            <th>Incentive</th>
                            <th>Commission %</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="table-font">
                        <scheme-rows v-for="(item,index) in schemes" v-bind:key="item.id" :index="index" :item="item"></scheme-rows>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </template>
    <scheme-form v-if="viewno==2"></scheme-form>
    
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import SchemeForm from './SchemeForm.vue'
import Constants from '../../components/utilities/Constants.vue'
import SchemeRows from './SchemeRows.vue'
export default {
    mixins:[Constants],
    components:{SchemeForm,SchemeRows},
    data(){
        return {
            activecount:0,
            totalemp:0,
            
        }
    },
    computed:{
		...mapGetters([
		    'viewno','edititem','schemes','loggedinuser'
		]),
	},
    mounted(){
		this.$store.dispatch('fetchschemes');
	},
    methods:{
        
        edit(item){
            this.$store.commit('assignedititem',item)
            this.$store.commit('assignviewno',2)
        },
        
        
    }
}
</script>

<style scoped>
.btn-primary:hover{
    text-decoration: none !important;
    background-color: #3a476b !important;
}
</style>