<template>
<div style="background-color:white">
    <div class="col-md-12" style="margin-top:20px">
        <div class="col-md-6">
            <h3 class="page-header">
            <i class="fa fa fa-user animated bounceInDown show-info"></i>
            Schemes
            </h3>
        </div>
    </div>

    <div class="info-box  bg-seagreen  text-white">
        <div class="info-icon bg-danger-dark">
            <i class="fa fa fa-user fa-2x"></i>
        </div>
        <div class="info-details">
            <h4>Add Schemes</h4>
        </div>
    </div>
          
    <form class="col-md-12" @submit.prevent="onSubmit" @keydown="keydownform($event)">
        
            
      <div class="panel panel-cascade">
          <div class="panel-body" >
                <div class="form-horizontal cascde-forms">

                    
                    <div class="form-group row CHK" >
                            <label class=" col-lg-4 col-md-3 control-label">
                            Item Name<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                            </label>
                            <div class="col-lg-6 col-md-9">
                                 <input class="form-control form-cascade-control input-small"  v-model="form.name" data-val="true" data-val-required="Required" id="NAME" name="NAME" type="text" value="" required>
                            </div>
                    </div>
                    <div class="form-group row">
                          <label class="col-lg-4 col-md-3 control-label">Recurring Type<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                          <div class="col-lg-6 col-md-9">
                            <select  v-model="form.recurringtype" class="form-control delradius form-cascade-control" required>
                                <option v-for="option in getmastertype(MASTER_RECURRING_TYPES)" v-bind:key="option.labelid" v-bind:value="option.labelid" v-text="option.label"/>
                              </select>
                            
                          </div>
                    </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-md-3 control-label">No. Of EMI<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                          <div class="col-lg-6 col-md-9">
                            <input class="form-control form-cascade-control input-small" v-model="form.numberofemi" id="NUMBEROFEMI" name="NUMBEROFEMI" type="number" value="" required>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-md-3 control-label">EMI<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                          <div class="col-lg-6 col-md-9">
                            <input class="form-control form-cascade-control input-small" v-model="form.emi" id="NUMBEROFEMI" name="NUMBEROFEMI" type="number" value="" required>
                          </div>
                        </div>
                    
                      <div class="form-group row">
                          <label class="col-lg-4 col-md-3 control-label">Total Amount<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                          <div class="col-lg-6 col-md-9">
                            <input class="form-control form-cascade-control input-small" v-model="form.totalamount" id="TOTALAMOUNT" name="TOTALAMOUNT" type="number" value="" required>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-md-3 control-label">Item Description</label>
                            <div class="col-lg-6 col-md-9">
                              <textarea class="form-control form-cascade-control input-small" v-model="form.description" cols="20" id="DESCRIPTION" name="DESCRIPTION" rows="2" style="height:83px;"></textarea>
                            </div>
                        </div>
                        <div class="form-group row leadbycustomer" style="display: none">
                          <div class="col-lg-6 col-md-9">
                            <span id="fill"></span>
                          </div>
                    </div>
                    <div class="col-md-12">   
                      <div class="row" style="text-align:center;">
                          <input type="button" style="background-color:#495b79!important;margin-top:23px;margin-left:35px;" value="Submit" @click="submit()" class="btn bg-primary text-white btn-sm chkcondition">
                          <input type="button" style="background-color:#495b79!important;margin-top:23px;margin-left:35px;" value="Cancel" @click="cancel()" class="btn bg-primary text-white btn-sm chkcondition">
                      </div>
              </div>
                </div>
            </div>
      </div>
             
         </form>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
export default {
    mixins:[Constants],
    
	data(){
		return {
            form: new window.Form({
            id:0,
			name:'',
            recurringtype:0,
            numberofemi:0,
            emi:0,
            totalamount:0,
            description:'',
        }),
		}
	},
     computed:{
       ...mapGetters([
		     'viewno','edititem',
		    ]),
    },
    mounted(){
          this.initializedata();
	},
	watch:{
        edititem:function(){ 
          this.initializedata();
        }
    },
	methods:{
        initializedata(){
            this.reset();
            if (this.edititem!=null){
                this.form.id=this.edititem.id;
                this.form.name=this.edititem.name;
                this.form.recurringtype=this.edititem.recurringtype;
                this.form.numberofemi=this.edititem.numberofemi;
                this.form.emi=this.edititem.emi;
                this.form.totalamount=this.edititem.totalamount;
                this.form.description=this.edititem.description;              
            }
        },
        keydownform(event){
			this.form.errors.clear(event.target.name);
			this.errormessage = '';
		},
        submit(){
            console.log("my form",this.form);
            this.form.submit(this,'post','api/scheme/createupdate')
	    	.then(response=>this.processResponse(response))
	    	.catch(error=>console.log(error));
        },
        processResponse(data){
            this.$store.commit('updateappendschemes',data)
            this.$store.commit('assignviewno',1)
            this.$notify({
                text:'Registered Successfully',
                type:'success',
                duration:'-1',
                speed:'100',
                closeOnClick:false
            })
        }, 
        cancel(){
            this.reset();
            this.$store.commit('assignviewno',1)
        },
        
	}
}
</script>